import React from 'react';
import {Switch, Route} from 'react-router-dom';
import App from './App';
import DealerView from './dealerview/dealerview';
export default class Router extends React.Component{
    render(){
        return(
            <Switch>
                <Route exact path='/' component={App} />
                <Route path='/dealerview' component={DealerView} />
            </Switch>
        );
    }
}